@font-face {
  font-family: brownSugar;
  src: url("./assets/font/BrownSugar-7BY4V.otf");
}

@font-face {
  font-family: regularMont;
  src: url("./assets/font/Montserrat-Regular.otf");
}

@font-face {
  font-family: regularBrownSugar;
  src: url("./assets/font/Brown\ Sugar\ .ttf");
}

@font-face {
  font-family: boldMont;
  src: url("./assets/font/Montserrat-Medium.otf");
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  outline: none;
}

html {
  height: 100%;
  overflow-x: hidden !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4;
  font-family: regularBrownSugar, sans-serif;
}
body {
  width: 100%;
  height: 100%;
  font-family: regularMont, sans-serif;
  color: var(--main-font);
  &::-webkit-scrollbar {
    width: 10px;
    @media (max-width: 1475px) {
      width: 9px;
    }
  }

  &::-webkit-scrollbar-track {
    background: #e2e2e2;
    border-radius: 1px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #474c55;
    border-radius: 1px;
  }
}

p {
  line-height: 1.7;
}

.rec-swipable {
  align-items: stretch;
}
.rec-carousel-item-visible {
  margin: 0;
  display: flex;
}

button {
  cursor: pointer;
}

.bg {
  position: absolute;
  left: 0%;
  top: 0%;
  bottom: 0%;
  right: 0%;
  z-index: 1;
}

.styledButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: max(0.35rem, 0.5vw) max(0.5rem, 2vw);
  border-radius: 0;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  border: 2px solid var(--main-color);

  @media (max-width: 900px) {
    padding: 0.5rem 1rem;
  }

  p {
    font-size: max(13.5px, 0.9vw);
    z-index: 4;
    transition: ease-in-out 0.2s;
    color: #000 !important;
    white-space: nowrap;
    position: relative;
  }

  svg {
    font-size: max(20px, 1vw);
    z-index: 4;
    transition: ease-in-out 0.2s;
    color: #000 !important;
    white-space: nowrap;
    position: relative;
  }

  &::before {
    content: "";
    border-radius: max(10px, 0.5vw);
    position: absolute;
    width: 140%;
    z-index: 1;
    height: 120%;
    top: -20%;
    left: -20%;
    bottom: -20%;
    right: -20%;
    background-color: var(--main-color);
    transition: ease-in-out 0.3s;
    color: #fff;
    transform: scale(1);
  }
  &:hover {
    &::before {
      transform: scale(0);
      top: 10%;
      left: 10%;
      bottom: 10%;
      right: 10%;
      width: 120%;
      height: 120%;
    }

    p {
      color: var(--main-color) !important;
    }

    svg {
      color: var(--main-color) !important;
    }
  }
}

.blackButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: max(0.35rem, 0.5vw) max(1rem, 2vw);
  border-radius: max(10px, 0.5vw);
  background-color: rgba($color: #1d1d1d, $alpha: 0.5);
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: ease-in-out 0.3s;
  border: none;
  border: 2px solid #1d1d1d;

  @media (max-width: 900px) {
    padding: 0.5rem 1rem;
  }

  p {
    font-size: max(13.5px, 0.9vw);
    z-index: 4;
    color: #fff !important;
    white-space: nowrap;
    position: relative;
  }

  .icon {
    font-size: max(20px, 1vw);
    z-index: 4;
    transition: ease-in-out 0.2s;
    color: #fff !important;
    white-space: nowrap;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::before {
    content: "";
    border-radius: max(10px, 0.5vw);
    position: absolute;
    width: 120%;
    z-index: 1;
    height: 120%;
    top: -10%;
    left: -10%;
    bottom: -10%;
    right: -10%;
    background-color: #1d1d1d;
    transition: ease-in-out 0.2s;
    color: #fff;
    transform: scale(1);
  }
  &:hover {
    &::before {
      transform: scale(0);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      transition: ease-in-out 0.35s;
    }
  }
}

.borderBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: max(0.35rem, 0.5vw) max(0.5rem, 2vw);
  border-radius: max(10px, 0.5vw);
  background: transparent;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: ease-in-out 0.4s;
  border: 1px solid var(--main-color);

  @media (max-width: 900px) {
    padding: 0.5rem 1rem;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: var(--main-color);
    transform: scale(0);
    border-radius: max(10px, 0.5vw);
    transition: ease-in 0.25s;
  }

  p {
    font-size: max(13.5px, 0.9vw);
    z-index: 2;
    color: var(--main-font);
    white-space: nowrap;
  }

  .icon {
    color: var(--main-color);
    font-size: max(20px, 1.5vw);
  }

  &:hover {
    border: 1px solid var(--main-color);

    &::after {
      transform: scale(1.1);
    }

    p {
      color: #fff !important;
    }

    .icon {
      color: #fff !important;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-transition: background-color 5000s ease-in-out 0s;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

.big-title {
  font-size: max(25px, 3.5vw);
}

.centered-text {
  text-align: center;
}
.mid-title {
  font-size: max(22px, 2.1vw);
}
.small-title {
  font-size: max(17px, 1vw);
}

span.color {
  color: var(--main-color);
}

.text {
  display: flex;
  flex-direction: column;
  gap: max(0.5rem, 1vw);
  text-align: center;
  align-items: center;
}

.uppercase {
  text-transform: uppercase;
}
.shared-h5 {
  font-size: max(15px, 0.9vw);
  color: var(--main-color);
  font-weight: 500;
}

.def-text {
  font-size: max(15px, 0.9vw);
}

.small-text {
  font-size: max(12px, 0.75vw);
}

.centered-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.hover-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: fit-content;
  background: var(--main-color);
  color: #fff;
  font-size: max(15px, 0.9vw);
  padding: max(12px, 0.7vw) max(22.5px, 2vw);
  border-radius: max(25px, 2vw);
  min-width: max(7vw, 110px);
  &:hover {
    animation: pulse-white 0.7s ease-in-out;
    -webkit-animation: pulse-white 0.7s ease-in-out;
  }
}
.hover-button2 {
  display: flex;
  align-items: center;
  color: var(--main-color);
  font-size: max(15px, 0.9vw);
  margin: max(7.5px, 0.5vw) 0;
  svg {
    margin-left: max(7px, 0.5vw);
  }
  &:hover svg {
    animation: hvr-icon-wobble-horizontal 1s ease-in-out 1;
    -webkit-animation: hvr-icon-wobble-horizontal 1s ease-in-out 1;
  }
}
.rec-arrow,
.rec-arrow-left,
.rec-arrow-right {
  border: 1px solid var(--main-color) !important;
  box-shadow: none !important;
  border-radius: 50% !important;
  background: var(--main-color) !important;
  width: max(30px, 2.75vw) !important;
  height: max(30px, 2.75vw) !important;
  min-width: max(30px, 2.75vw) !important;
  min-height: max(30px, 2.75vw) !important;
  max-width: max(30px, 2.75vw) !important;
  max-height: max(30px, 2.75vw) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: max(15px, 1vw) !important;
  color: var(--main-font) !important;
  position: relative;
  &:hover {
    color: var(--main-font) !important;
    background: var(--main-color) !important;
  }
}

.rec-dot {
  width: max(11px, 0.75vw) !important;
  height: max(11px, 0.7vw) !important;
  margin: max(5px, 0.35vw) !important;
  border: 0 !important;
  box-shadow: none !important;
  background: var(--jobs-icon) !important;
  border-radius: max(10px, 1vw) !important;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none !important;
    background: rgba(var(--main-rgb), 1) !important;
  }
  &:active,
  &:focus {
    box-shadow: 0 0 0 max(3px, 0.25vw) rgba(var(--main-rgb), 0.2) !important;
  }
}
.rec-dot_active {
  background: var(--main-color) !important;
  box-shadow: 0 0 0 max(3px, 0.25vw) rgba(var(--main-rgb), 0.2) !important;
}

@keyframes pulse-white {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--main-color), 0.5);
  }
  70% {
    box-shadow: 0 0 0 1.2rem rgba(128, 173, 217, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(128, 173, 217, 0);
  }
}
@-webkit-keyframes pulse-white {
  0% {
    box-shadow: 0 0 0 0 rgba(var(--main-color), 0.5);
  }
  70% {
    box-shadow: 0 0 0 1.2rem rgba(128, 173, 217, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(128, 173, 217, 0);
  }
}

//Animation ----------------------------------------------------
@-webkit-keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes hvr-icon-wobble-horizontal {
  16.65% {
    -webkit-transform: translateX(6px);
    transform: translateX(6px);
  }
  33.3% {
    -webkit-transform: translateX(-5px);
    transform: translateX(-5px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
    transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
    transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
//--------------------------------------
@-webkit-keyframes hvr-icon-down {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
  75% {
    -webkit-transform: translateY(max(7.5px, 0.75vw));
    transform: translateY(max(7.5px, 0.75vw));
  }
}
@keyframes hvr-icon-down {
  0%,
  50%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  25%,
  75% {
    -webkit-transform: translateY(max(7.5px, 0.75vw));
    transform: translateY(max(7.5px, 0.75vw));
  }
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.51;
  }
  25% {
    opacity: 0.35;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.25;
  }
  75% {
    opacity: 0.15;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.05;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.51;
  }
  25% {
    opacity: 0.35;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.25;
  }
  75% {
    opacity: 0.15;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.05;
  }
}

@-webkit-keyframes scale-in-center2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.41;
  }
  25% {
    opacity: 0.35;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.25;
  }
  75% {
    opacity: 0.15;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.05;
  }
}
@keyframes scale-in-center2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.41;
  }
  25% {
    opacity: 0.35;
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.25;
  }
  75% {
    opacity: 0.15;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.05;
  }
}

//color switch and dark/light mode -------------------------------------------
:root {
  --main-color: #fff;
  --main-font: #1d1d1d;
  --main-navy: #000e2b;
  --main-comp: #fff;
  --main-rgb: 29, 29, 29;
  --main-black: #000;
  --dark-bg: #fff;
  --dark-body: #fff;
  --main-border: #cfcfcf;
  --main-gray: #808080;
  --main-icon: #9d9d9d;
  --jobs-gray: #4b4b4b;
  --jobs-icon: #a8a8a8;
  --star-filled: #ffaa00;
  --star-unfilled: #949494;
  --main-alert: #e14545;
  --second-color: #84ce3f;
  --footer-text: #e4e4e4;
  --second-border: #e7e7e7;
  --dark-comp: #fff;
  --main-reverse: #fff;
}

body.dark-mode {
  --main-font: #fff;
  --main-gray: rgb(201, 201, 201);
  background: #0d0f0e;
  --dark-bg: #0d0f0e;
  --dark-body: #1a171d;
  --dark-border: #6d9af3;
  --dark-comp: #070707;
  --main-black: #fff;
  --main-border: #464646;
  --main-reverse: #1d1d1d;
  --main-comp: #1d1d1d;
}

//media queries--------------------------------------------------
@media (max-width: 768px) {
  .shared-text,
  .shared-h5,
  .hover-button2 {
    font-size: 16px;
  }
}
